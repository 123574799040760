import { HookScrollContainerRefContext } from './useScrollContainerRefContext';

import { useContext, useEffect, useState } from 'react';

/**
 * スクロールバーを除いたスクロールコンテナの幅を取得する
 */
export const useScrollContainerWidth = () => {
  const context = useContext(HookScrollContainerRefContext);
  const [width, setWidth] = useState(context?.current?.clientWidth ?? 0);

  useEffect(() => {
    const scrollContainer = context?.current;

    if (scrollContainer) {
      // メニューの開閉での幅変更に対応するためにwindow.onResizeを使わずにResizeObserverを使っている
      const observer = new ResizeObserver(() =>
        setWidth(scrollContainer.clientWidth),
      );

      observer.observe(scrollContainer);
      return () => observer.unobserve(scrollContainer);
    }
  }, [context?.current]);

  return { scrollContainerWidth: width };
};
